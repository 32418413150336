<style lang="scss">
#debug {
  display: none;
}
#frontend {
  .karriere-all {
    .header-intro-generel-box {
      background-position:center center !important;
    }
    .person-box {
      position:relative;
      top:-50px;
      .txt-box {
        text-align:center;
        padding:60px 15px;
        max-width:50%;
        margin-left:auto;
        margin-right:auto;
        .name {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size:200%;
        }
        .zitat {
          font-family: 'American Typewriter ITC W04 Md';
          margin-top:20px;
          margin-bottom:40px;
          font-size:140%;
        }
        p {
          strong {
            display:block;
            font-family: 'Helvetica Neue LT W05 55 Roman';
          }
        }
      }
      .box-middle {
        border:1px solid #878787;
      }
      .box-bottom {
        text-align:right;
        border:1px solid #878787;
        border-top:0;
        padding:25px;
        a {
          display:inline-block;
          img {
            width:30px;
            height:auto;
          }
        }
      }
    }
    .img-txt-box-outer {
      position:relative;
      margin-bottom:25px;
      .img-box {
        width:100%;
        background-size:cover;
        background-position:center center;
        position:relative;
        padding:25px;
        @media(max-width:$width-lg) {
          background-image:none !important;
        }
        img {
          display:none;
          @media(max-width:$width-lg) {
            display:block;
          }
        }
      }
      .img-txt-outer {
        position:absolute;
        width:100%;
        height:100%;
        top:0;
        left:0;
        text-align:center;
        //background:#fff;
        transition:$transition-std;
        opacity: 0;
        padding:25px;
        @media(max-width:$width-lg) {
          opacity: 1;
          position:relative;
          top:0;
          

        }
        .img-txt-inner-outer {
          background:#fff;
          position:relative;
          height:100%;
          width:100%;
        }
       
        .img-txt-inner {
          position:relative;
          top:50%;
          transform: translateY(-50%);
          max-width:70%;
          margin:0px auto;
          @media(max-width:$width-lg) {
            top:0;
            transform: translateY(0%);
            margin:40px auto;
            max-width:100%;
          }
          @media(max-width:$width-lg) {
            margin:0 auto 40px auto;
            
          }

        }
        h2 {
          font-family: 'Helvetica Neue LT W05_75 Bold';
          font-size:220%;
          @media(max-width:$width-sm) {
            font-size:180%;
          }
        }
        span {
          display:block;
          font-family: 'Helvetica Neue LT W05 35 Thin';
          margin-top:20px;
          margin-bottom:40px;
        }
        p {
          font-family: 'Helvetica Neue LT W05 35 Thin';
        }
        .kategorie {
          position:absolute;
          width:100%;
          bottom:30px;
          font-size:90%;
          text-align:center;
          font-family: 'Helvetica Neue LT W05 65 Medium';
          text-transform: uppercase;
          letter-spacing: 2px;
          @media(max-width:$width-lg) {
            position:relative;
            bottom:0;
            border-bottom:1px solid #000;
            padding-bottom:15px;
          }
        }
        
      }
      &:hover {
        .img-txt-outer {
          opacity: 1;
        }
      }
    }
    
    .height-xxl {
      height:700px;
      @media(max-width:$width-xl) {
        height:500px;
      }
      @media(max-width:$width-lg) {
        height:auto;
      }
    }
    .height-m {
      height:500px;
      @media(max-width:$width-lg) {
        height:auto;
      }
    }
    
  }
}
</style>

<template>
  <SocialMediaIconBox />
  <div class="karriere-all">
    <!--<HeaderImageBoxGenerel :style="{ 'background-image': `url(${require('@/assets/img/karriere/header-img-top.jpg')})` }"  :headerImageText="$t('General.karriere-weiterbildung')" />-->
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="headline-box-std">
            <div class="headline-inner">
              <div class="breadcrumb"><span>{{$t('General.karriere')}}</span></div>
              <h1 v-html="$t('karriere-weiterbildung.karriere-weiterbildung')"></h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid bg-violet">
      <div class="row justify-content-center">
        <div class="col-lg-7 col-md-7">
          <p class="intro-txt text-center color-fff padding-tb-l">{{$t('karriere-weiterbildung.karriere-weiterbildung-intro-txt')}}</p>
        </div>
      </div>
    </div>

    <div class="container margin-t-l">
      <div class="row justify-content-center align-self-center">
        <div class="col-lg-12" v-inview="['move-in-from-top']">
          <div class="img-txt-box-outer">
            <div class="img-box height-xxl" :style="{ 'background-image': `url(${require('@/assets/img/karriere/karriere-weiterbildung-v1.jpg')})` }"> 
              <img src="@/assets/img/karriere/karriere-weiterbildung-v1.jpg" alt="Onboarding" class="img-fluid">
            </div>
            <div class="img-txt-outer">
              <div class="img-txt-inner-outer">
                <div class="img-txt-inner">
                  <h2>{{$t('karriere-weiterbildung.onboarding-hl')}}</h2>
                  <span>{{$t('karriere-weiterbildung.onboarding-hl-v2')}}</span>
                  <p>{{$t('karriere-weiterbildung.onboarding-txt')}}</p>
                  
                </div>
                <div class="kategorie">Onboarding</div>
              </div>
            </div>
          </div>
          
        </div>
        <div class="col-lg-6" >
          <div class="img-txt-box-outer" v-inview="['move-in-from-left']">
            <div class="img-box height-m" :style="{ 'background-image': `url(${require('@/assets/img/karriere/karriere-weiterbildung-v2.jpg')})` }" style="background-position:left center;"> 
              <img src="@/assets/img/karriere/karriere-weiterbildung-v2.jpg" alt="Leadership" class="img-fluid">
            </div>
            <div class="img-txt-outer">
              <div class="img-txt-inner-outer">
                <div class="img-txt-inner">
                  <h2>{{$t('karriere-weiterbildung.leadership-hl')}}</h2>
                  <p>{{$t('karriere-weiterbildung.leadership-txt')}}</p>  
                </div>
                <div class="kategorie">Leadership</div>
              </div>
            </div>
          </div>
          <!---->
          <div class="img-txt-box-outer" v-inview="['move-in-from-left']">
            <div class="img-box height-xxl" :style="{ 'background-image': `url(${require('@/assets/img/karriere/karriere-weiterbildung-redaktion.jpg')})` }" > 
              <img src="@/assets/img/karriere/karriere-weiterbildung-redaktion.jpg" alt="Redaktion" class="img-fluid">
            </div>
            <div class="img-txt-outer">
              <div class="img-txt-inner-outer">
                <div class="img-txt-inner">
                  <h2>{{$t('karriere-weiterbildung.redaktion-hl')}}</h2>
                  <p>{{$t('karriere-weiterbildung.redaktion-txt')}}</p>  
                </div>
                <div class="kategorie">{{$t('karriere-weiterbildung.redaktion-hl-v2')}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="img-txt-box-outer" v-inview="['move-in-from-right']">
            <div class="img-box height-xxl" :style="{ 'background-image': `url(${require('@/assets/img/karriere/karriere-weiterbildung-sales.jpg')})` }"> 
              <img src="@/assets/img/karriere/karriere-weiterbildung-sales.jpg" alt="Sales" class="img-fluid">
            </div>
            <div class="img-txt-outer">
              <div class="img-txt-inner-outer">
                <div class="img-txt-inner">
                  <h2>{{$t('karriere-weiterbildung.sales-hl')}}</h2>
                  <p>{{$t('karriere-weiterbildung.sales-txt')}}</p>  
                </div>
                <div class="kategorie">{{$t('karriere-weiterbildung.sales-hl')}}</div>
              </div>
            </div>
          </div>
          <!---->
          <div class="img-txt-box-outer" v-inview="['move-in-from-right']">
            <div class="img-box height-m" :style="{ 'background-image': `url(${require('@/assets/img/karriere/karriere-weiterbildung-v5.jpg')})` }"> 
              <img src="@/assets/img/karriere/karriere-weiterbildung-v5.jpg" alt="Leadership" class="img-fluid">
            </div>
            <div class="img-txt-outer">
              <div class="img-txt-inner-outer">
                <div class="img-txt-inner">
                  <h2>{{$t('karriere-weiterbildung.talente-hl')}}</h2>
                  <p>{{$t('karriere-weiterbildung.talente-txt')}}</p>  
                </div>
                <div class="kategorie">{{$t('karriere-weiterbildung.talente-hl')}}</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

    
  </div>
  <Footer />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Aus- und Weiterbildung | Styria Media Group',
      keywords: ['ausbildung','weiterbildung','fähigkeiten','styria skillsacadamy','onboarding','styria media group'],
      description: 'Erfahren Sie wie der Bewerbungsprozess bei der Styria Media Group abläuft.',
      image: require('@/assets/img/og/karriere.png'),
      
    },
    en: {
      title: 'Training and further education | Styria Media Group',
      keywords: ['ausbildung','weiterbildung','fähigkeiten','styria skillsacadamy','onboarding','styria media group'],
      description: 'STYRIA supports your skills with attractive training and development programmes.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
</script>
