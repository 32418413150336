
import { defineComponent } from 'vue';

import SocialMediaIconBox from '../../../components/Frontend/SocialMediaIconBox.vue';
import Footer from '../../../components/Frontend/Footer.vue';
import HeaderImageBoxGenerel from '../../../components/Frontend/HeaderImageBoxGenerel.vue';
import { mdiChevronUp } from '@mdi/js';

export default defineComponent({
  meta: {
    de: {
      title: 'Aus- und Weiterbildung | Styria Media Group',
      keywords: ['ausbildung','weiterbildung','fähigkeiten','styria skillsacadamy','onboarding','styria media group'],
      description: 'Erfahren Sie wie der Bewerbungsprozess bei der Styria Media Group abläuft.',
      image: require('@/assets/img/og/karriere.png'),
      
    },
    en: {
      title: 'Training and further education | Styria Media Group',
      keywords: ['ausbildung','weiterbildung','fähigkeiten','styria skillsacadamy','onboarding','styria media group'],
      description: 'STYRIA supports your skills with attractive training and development programmes.',
      image: require('@/assets/img/og/karriere.png'),
    },
  },
  components: {
    SocialMediaIconBox,
    //HeaderImageBoxGenerel,
    Footer
  },
  setup() {
    return {
      mdiChevronUp,
    };
  },
});
